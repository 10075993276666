import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BusinessLogger,
	BusinessLoggerSymbol,
	IPageWillMountHandler,
	IPageWillUnmountHandler,
	IPropsStore,
	PageFeatureConfigSymbol,
	pageIdSym,
	Props,
	SiteFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { ITpaSrcBuilder, TpaCompData, TpaPageConfig } from './types'
import { name, TpaSrcBuilderSymbol } from './symbols'
import {
	name as tpaCommonsName,
	MasterPageTpaPropsCacheSymbol,
	MasterPageTpaPropsCache,
	TpaCommonsSiteConfig,
} from 'feature-tpa-commons'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import _ from 'lodash'

export const Tpa = withDependencies(
	[
		Props,
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(PageFeatureConfigSymbol, name),
		SessionManagerSymbol,
		BusinessLoggerSymbol,
		pageIdSym,
		MasterPageTpaPropsCacheSymbol,
		TpaSrcBuilderSymbol,
	],
	(
		props: IPropsStore,
		{ widgetsClientSpecMapData, isMobileView }: TpaCommonsSiteConfig,
		{ widgets }: TpaPageConfig,
		sessionManager: ISessionManager,
		businessLogger: BusinessLogger,
		pageId: string,
		{ cacheProps, getCachedProps }: MasterPageTpaPropsCache,
		tpaSrcBuilder: ITpaSrcBuilder
	): IPageWillMountHandler & IPageWillUnmountHandler => {
		const tpas = _.pickBy(widgets, ({ widgetId, isOOI }) => !isOOI && widgetsClientSpecMapData[widgetId])

		const getCompId = (id: string, { templateId }: TpaCompData) => templateId ?? id

		const buildSrc = (id: string, tpaCompData: TpaCompData) => {
			const { widgetUrl, mobileUrl } = widgetsClientSpecMapData[tpaCompData.widgetId]
			const baseUrl = isMobileView ? mobileUrl || widgetUrl : widgetUrl
			return tpaSrcBuilder.buildSrc(id, tpaCompData, baseUrl)
		}

		return {
			async pageWillMount() {
				sessionManager.addLoadNewSessionCallback(({ reason }) => {
					if (reason === 'memberLogin') {
						Object.entries(tpas).forEach(([id, tpaCompData]) => {
							props.update({
								[id]: {
									src: buildSrc(id, tpaCompData),
								},
							})
						})
					}
				})
				Object.entries(tpas).forEach(([id, tpaCompData]) => {
					const { widgetId } = tpaCompData
					const { appDefinitionId, appDefinitionName, appPage } = widgetsClientSpecMapData[widgetId]
					const reportIframeStartedLoading = _.once(() => {
						businessLogger.logger.log(
							{
								appId: appDefinitionId,
								widget_id: widgetId,
								instance_id: getCompId(id, tpaCompData),
								src: 42,
								// APP_IFRAME_START_LOADING
								evid: 642,
							},
							{ endpoint: 'ugc-viewer' }
						)
					})

					const defaultProps = {
						title: appPage.name ?? appDefinitionName,
						appDefinitionName,
						isMobileView,
						reportIframeStartedLoading,
					}

					const cachedProps = getCachedProps(id)
					if (cachedProps) {
						props.update({
							[id]: {
								...defaultProps,
								...(cachedProps as any),
							},
						})
						return
					}

					props.update({
						[id]: {
							...defaultProps,
							src: buildSrc(id, tpaCompData),
						},
					})
				})
			},
			pageWillUnmount() {
				if (pageId === 'masterPage') {
					Object.keys(tpas).forEach(cacheProps)
				}
			},
		}
	}
)
